<template>
  <div id="admissionForm">
    <div class="container">
      <h2 id="admissionFormHead">
        Admission Form
      </h2>
      <h5>Part {{this.sectionIndex}} of 10</h5>
       <input
          type="button"
          value="Back"
          id="backBt"
          v-on:click="decreaseSectionIndex"
          v-if="this.sectionIndex != 1"
        />
        <input
          type="button"
          value="Next"
          id="nextBt"
          v-on:click="increaseSectionIndex"
          v-if="this.sectionIndex != 10"
        />
      <form
        action=""
        id="admissionForm"
      >

        <div
          id="admissionFormSection_1"
          class="admissionFormSection"
          v-show="this.sectionIndex === 1"
        >
          <h5 class="admissionFormSectionHead">Childs's Details</h5>
          <div class="formElement"><label for="child_name">Full Name
            </label><input
              type="text"
              name="child_name"
              id="child_name"
            ></div>
          <div class="formElement"><label for="name_child_responds">Name the Child Responds to
            </label><input
              type="text"
              name="name_child_responds"
              id="name_child_responds"
            ></div>
          <div class="formElement"><label for="child_dob">Date of Birth
            </label><input
              type="text"
              name="child_dob"
              id="child_dob"
            ></div>
          <div class="formElement"><label for="aadhar_number">Aadhar Number
            </label><input
              type="text"
              name="aadhar_number"
              id="aadhar_number"
            /></div>
          <div class="formElement"><label for="communication_address">Communication Address
            </label><textarea
              name="communication_address"
              id="communication_address"
            ></textarea>
          </div>
          <div class="formElement"><label for="languages_spoken_at_home">Language(s) spoken at home
            </label><input
              type="text"
              name="languages_spoken_at_home"
              id="languages_spoken_at_home"
            ></div>
        </div>
        <div
          id="admissionFormSection_2"
          class="admissionFormSection"
          v-show="this.sectionIndex === 2"
        >
          <h5 class="admissionFormSectionHead">Mother's Details</h5>
          <div class="formElement"><label for="mothers_name">Full Name
            </label><input
              type="text"
              name="mothers_name"
              id="mothers_name"
            ></div>
          <div class="formElement"><label for="mothers_email">Email
            </label><input
              type="text"
              name="mothers_email"
              id="mothers_email"
            ></div>
          <div class="formElement"><label for="mothers_mobile">Mobile Number
            </label><input
              type="text"
              name="mothers_mobile"
              id="mothers_mobile"
            ></div>
          <div class="formElement"><label for="mothers_profession">Profession
            </label><input
              type="text"
              name="mothers_profession"
              id="mothers_profession"
            ></div>
          <div class="formElement"><label for="mothers_communication">Send Communication

            </label><input
              type="text"
              name="mothers_communication"
              id="mothers_communication"
            ></div>
        </div>
        <div
          id="admissionFormSection_3"
          class="admissionFormSection"
          v-show="this.sectionIndex === 3"
        >
          <h5 class="admissionFormSectionHead">Father's Details</h5>
          <div class="formElement"><label for="fathers_name">Full Name
            </label><input
              type="text"
              name="fathers_name"
              id="fathers_name"
            ></div>
          <div class="formElement"><label for="fathers_email">Email
            </label><input
              type="text"
              name="fathers_email"
              id="fathers_email"
            ></div>
          <div class="formElement"><label for="fathers_mobile">Mobile Number
            </label><input
              type="text"
              name="fathers_mobile"
              id="fathers_mobile"
            ></div>
          <div class="formElement"><label for="fathers_profession">Profession
            </label><input
              type="text"
              name="fathers_profession"
              id="fathers_profession"
            ></div>
          <div class="formElement"><label for="fathers_communication">Send Communication

            </label><input
              type="text"
              name="fathers_communication"
              id="fathers_communication"
            ></div>
        </div>

        <div
          id="admissionFormSection_4"
          class="admissionFormSection"
          v-show="this.sectionIndex === 4"
        >
          <h5 class="admissionFormSectionHead">Emergency Contact Details</h5>
          <div class="formElement"><label for="emergency_name">Name</label><input
              type="text"
              name="emergency_name"
              id="emergency_name"
            ></div>
          <div class="formElement"><label for="emergency_relationship">Relationship</label><input
              type="text"
              name="emergency_relationship"
              id="emergency_relationship"
            ></div>
          <div class="formElement"><label for="emergency_mobile">Mobile</label><input
              type="text"
              name="emergency_mobile"
              id="emergency_mobile"
            ></div>
          <div class="formElement"><label for="emergency_alternative">Alternative</label><input
              type="text"
              name="emergency_alternative"
              id="emergency_alternative"
            ></div>
        </div>
        <div
          id="admissionFormSection_5"
          class="admissionFormSection"
          v-show="this.sectionIndex === 5"
        >
          <h5 class="admissionFormSectionHead">Details of previous schools attended</h5>
          <label>Number Of Previous Schools</label>
          <select @change="onSchoolsChange($event)">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
          <div class="schoolSectionContainer">
            <div class="schoolSectionContainerElement"  v-for="(n,index) in this.schools"
              :key="index">
              <div class="formElement"><label :for="'school_name_'+n">Name and Location of School
                </label><input
                  type="text"
                  :name="'school_name_'+n"
                  :id="'school_name_'+n"
                ></div>
              <div class="formElement"><label :for="'school_grade_'+n">Grade
                </label><input
                  type="text"
                  :name="'school_grade_'+n"
                  :id="'school_grade_'+n"
                ></div>
            </div>            
          </div>
        </div>
        <div
          id="admissionFormSection_6"
          class="admissionFormSection"
          v-show="this.sectionIndex === 6"
        >
          <h5 class="admissionFormSectionHead">Help us know your child better
          </h5>
          <div class="formElement columnElement"><label for="expectations">What expectations do you have of this school?
            </label><input
              type="text"
              name="expectations"
              id="expectations"
            ></div>
          <div class="formElement columnElement"><label for="interests">What are your child’s interests?
            </label><input
              type="text"
              name="interests"
              id="interests"
            ></div>
          <div class="formElement columnElement"><label for="upset_child">What can upset your child?
            </label><input
              type="text"
              name="upset_child"
              id="upset_child"
            ></div>
        </div>
        <div
          id="admissionFormSection_7"
          class="admissionFormSection"
          v-show="this.sectionIndex === 7"
        >
          <h5 class="admissionFormSectionHead">Health and Medication
          </h5>
          <div class="formElement columnElement">
            <label for="pediatrician">Pediatrician Details (Name, Address, Phone Number)</label>
            <input
              type="text"
              name="pediatrician"
              id="pediatrician"
            >
          </div>
          <div class="formElement columnElement">
            <label for="allergies">Does your child have any illness or allergies that the school needs to know about?
            </label>
            <input
              type="text"
              name="allergies"
              id="allergies"
            >
          </div>
        </div>
        <div
          id="admissionFormSection_8"
          class="admissionFormSection"
          v-show="this.sectionIndex === 8"
        >
          <h5 class="admissionFormSectionHead">Remedial
          </h5>
          <div class="formElement columnElement"><label for="behavioural_needs">Has your child been recommended for, or evaluated, in or out of school for possible learning or
              behavioural needs? (If yes, please explain and submit a copy of the same)
            </label><input
              type="text"
              name="behavioural_needs"
              id="behavioural_needs"
            ></div>
          <div class="formElement columnElement"><label for="special_needs">Does your child have any special physical, emotional, psychological or language needs?
            </label><input
              type="text"
              name="special_needs"
              id="special_needs"
            ></div>
          <div class="formElement columnElement"><label for="frequency_of_therapists_involvement">What is (was) the frequency of involvement of therapists for the various needs? (Please detail the
              services as well)
            </label><input
              type="text"
              name="frequency_of_therapists_involvement"
              id="frequency_of_therapists_involvement"
            ></div>
        </div>
        <div
          id="admissionFormSection_9"
          class="admissionFormSection"
          v-show="this.sectionIndex === 9"
        >
          <h5 class="admissionFormSectionHead">Sibling(s) details
          </h5>
          <label>Number Of Siblings</label>
          <select @change="onSiblingsChange($event)">
            <option value="0">0</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
          </select>
          <div class="siblingDetailsContainer">
            <div
              class="siblingDetailsElement"
              v-for="(n,index) in this.siblings"
              :key="index"
            >
              <div class="formElement"><label :for="'siblings_name-'+n">Name</label><input
                  type="text"
                  :name="'siblings_name-'+n"
                  :id="'siblings_name-'+n"
                ></div>
              <div class="formElement"><label :for="'siblings_age-'+n">Age</label><input
                  type="text"
                  :name="'siblings_age-'+n"
                  :id="'siblings_age-'+n"
                ></div>
              <div class="formElement"><label :for="'siblings_age-'+n">School</label><input
                  type="text"
                  :name="'siblings_age-'+n"
                  :id="'siblings_age-'+n"
                ></div>
            </div>

          </div>
        </div>
        <div
          id="admissionFormSection_10"
          class="admissionFormSection"
          v-show="this.sectionIndex === 10"
        >
          <h5 class="admissionFormSectionHead">Final Part
          </h5>
          <div class="formElement columnElement radioElement">
            <label for="transportation">Does your child need transportation?
            </label>
            <div>
              <input
                type="radio"
                name="transportation"
                id="transportation"
                value="Yes"
              >
              Yes
              <input
                type="radio"
                name="transportation"
                id="transportation"
                value="No"
              >
              No
            </div>

          </div>
          <div class="formElement columnElement radioElement">
            <label for="regularSchools">Will your child be attending EKAM for the regular school, therapies or both?
            </label>
            <div>
              <input
                type="radio"
                name="regularSchools"
                id="regularSchools"
                value="Yes"
              >
              Yes
              <input
                type="radio"
                name="regularSchools"
                id="regularSchools"
                value="No"
              >
              No
            </div>
          </div>
          <div class="formElement columnElement radioElement">
            <label for="anyOtherInformation">Any other information you would like to share with the school?
            </label>
            <div>
              <input
                type="radio"
                name="anyOtherInformation"
                id="anyOtherInformation"
                value="Yes"
              >
              Yes
              <input
                type="radio"
                name="anyOtherInformation"
                id="anyOtherInformation"
                value="No"
              >
              No
            </div>
          </div>
          <div
            class="formElement columnElement"
            style="border:none"
          >
            <h5 class="admissionFormSectionHead">Please check one:</h5>
            <div style="margin:0.5rem 0">
              <input
                type="radio"
                name="privacy"
                id="privacy"
                value="I give permission to photograph my child for the school’s website and social media purposes,
and also the permission to use my child’s first name."
              >
              I give permission to photograph my child for the school’s website and social media purposes,
              and also the permission to use my child’s first name.
            </div>
            <div style="margin:0.5rem 0">
              <input
                type="radio"
                name="privacy"
                id="privacy"
                value="I give permission to photograph my child, but do not want my child’s name used along with
the photograph."
              >
              I give permission to photograph my child, but do not want my child’s name used along with
              the photograph.
            </div>
            <div style="margin:0.5rem 0">
              <input
                type="radio"
                name="privacy"
                id="privacy"
                value="I do not want my child photographed and do not want his or her name used."
              >
              I do not want my child photographed and do not want his or her name used.

            </div>
          </div>
          <input
            type="button"
            value="Submit"
            id="submitBt"
          />
        </div>
       
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sectionIndex: 1,
      siblings: 0,
      schools:0
    }
  },
  methods: {
    increaseSectionIndex () {
      this.sectionIndex = this.sectionIndex + 1
    },
    decreaseSectionIndex () {
      this.sectionIndex = this.sectionIndex - 1
    },
    onSiblingsChange (event) {
      this.siblings = parseInt(event.target.value);
    },
    onSchoolsChange (event) {
      this.schools = parseInt(event.target.value);
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  padding: 0 $commonLeftRightPadding;
  margin: 4rem auto;
}
#admissionFormHead {
  color: $yellow3;
  font-weight: 400;
  margin-bottom: 1rem 0;
}
.columnElement {
  flex-direction: column !important;
  label {
    width: 100% !important;
    margin-bottom: 0.5rem !important;
  }
}
.radioElement {
  border-bottom: 0 !important;
  :not(label) {
    color: $yellow2 !important;
  }
}

.formElement {
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
 
  @include media-md {
    flex-direction: row;
  }
}
.admissionFormSectionHead {
  color: $red;
}

label,
input,
textarea,
select {
  padding: 0;
  margin: 0 3px;
  border: none;
  background-color: transparent;
}
input,
textarea {
  flex: 1;
  font-size: 1rem;
   border-bottom: 1px solid $yellow2;
}
label {
  width: 250px;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  @include media-md {
    margin-bottom: 0;
  }
}
textarea {
  min-height: 100px;
}
input[type="button"] {
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 1rem;
  color: $white;
  cursor: pointer;

}
select {
  border: 1px solid $yellow2;
  background-color: transparent;
  margin: 3px;
  font-size: 1rem;
}
#submitBt {
  margin-right: 1rem;
  background-color: $yellow3;
}
#nextBt {
  margin-right: 1rem;
  color: $brown;
  padding-left:0;
  //border:1px solid  $brown;
}
#backBt {
  color: $black;
  margin-right: 1rem;
  padding-left:0;
   //border:1px solid  $black;
}
.siblingDetailsElement,.schoolSectionContainerElement {
  margin-bottom: 1rem;
  padding: $commonLeftRightPadding 0;
  background-color: #f2f2f2;
}
</style>

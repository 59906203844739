<template>
  <section id="eventsGalleryMainContainer">
    <div class="item">
      <h3 class="heading">Monsoon Magic -Reading Programmes for Children</h3>
      <p class="">
        A 38-week Reading programme for children with monthly themes and concepts, a whole great time reading with world class children’s literature!
      </p>
      <p>08 Sep 2020</p>
    </div>
    <!---Event Gallery Section-->
    <div id="workContentSection">
      <div id="workGridSection">

        <div class="workGridDiv">
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/2.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/3.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/4.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/1.jpg')"
          ></expandable-image>
        </div>

        <div class="workGridDiv">
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/6.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/7.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/8.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/10.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/5.jpg')"
          ></expandable-image>
        </div>

        <div class="workGridDiv">
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/11.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/12.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/13.jpg')"
          ></expandable-image>
          <expandable-image
            class="workGrid"
            :src="require('@/assets/image/events/1/9.jpg')"
          ></expandable-image>
        </div>

      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: "events"
}
</script>
<style lang="scss" scoped>
#eventsGalleryMainContainer {
  height: auto;
  min-height: 100vh;
  width: 100%;
  max-width: 1024px;
  padding: 0 $commonLeftRightPadding;
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  .item {
    margin: 0;
    margin-bottom: 3rem;
  }
  .heading {
    width: 100%;
    max-width: 600px;
    color: $yellow3;
    margin: 0 0 1rem 0;
    display: inline-block;
  }
}
//event gallery//
#workGridSection {
 width: 100%;
 display: flex;
 flex-wrap: wrap;
 padding: .5rem 0;
 margin-top: 2rem;
 transition: all .3s;

 @include media-md {
  padding: 0 2rem;
 }

 .workGridDiv {
  width: 100%;
  transition: all .3s;

  @include media-sm {
   width: 33.33%;
  }

  .workGrid {
      width: 100%;
   padding: .5rem;
   transition: all .3s;
   cursor: zoom-in;
  }
 }
}
</style>
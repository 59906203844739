<template>
  <div class="Gallery">
    <div id="galleryMainContainer">
      <div class="container">
        <expandable-image
           v-for="index in 21" :key="index"
          class="image imageCard"
          
          :src="require('@/assets/image/'+index+'.jpg')"
        ></expandable-image>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "gallery"
}
</script>
<style lang="scss" scoped>
#galleryMainContainer {
  width: 100%;
  height: auto;
  .container {
    display: flex;
    width: inherit;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 $commonLeftRightPadding;
    margin: 4rem auto;
    justify-content: center;
    .expandable-image {
      box-shadow: 0 1px 4px 0 #b6b6b6;
      border-radius: 0.2rem;
      transition: all 0.5s;
      padding:0;
      &:hover {
        box-shadow: unset;
      }
    }

    .imageCard {
      width: 70vw;
      max-width: 300px;
      //height:60vw;
      max-height: 300px;
      margin: 1rem;
    }
    .imageView {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
  }
}
</style>


<template>
  <div class="about">
    <div id="aboutMainContaier">
      <div class="innerSection">
        <h3 class="heading">Finnish School</h3>
        <p>Finnish curriculum provides the flexibility to cater to all children through various means to find
          their self and develop their cognitive skills by understanding and exploring their surrounding in
          their day to day life.</p>

        <p>Ekam strives to be a successful example for integration. We have worked together as a team to
          understand, research and meet with the directors of some of the schools in Finland to bring in the
          best of the curriculum and ideas.</p>
      </div>
      <div class="innerSection">
        <h3 class="heading">Soumya Jampana</h3>
        <h4 class="designation">Co Founder & MD</h4>
        <p>She is a graduate from the University of Kansas and holds a specialization in inclusion and
          early childhood along with a degree in child Psychology.</p>
        <p>She aims to create awareness and understand the importance of integration in schools.
          Through Ekam she wants to enhance the values in every child that will help them become a
          better personality and develop curiosity to understand themselves and their surroundings.</p>

      </div>
      <div class="innerSection">
        <h3 class="heading">Misba Farheen</h3>
        <h4 class="designation">Co Founder & Academic Director</h4>
        <p>She has a degree in Bachelors in science (B.Sc), and holds a certificate in Teacher training
          education from university of NDNU, California. She is highly passionate about guiding
          children acquire essential skills for life and for the interconnected world that we live in.</p>
        <p>Through Ekam she aims at providing a well-rounded education, creative, engaging and
          immersive learning experiences for the children and by helping them break boundaries and
          become independent learners.</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#aboutMainContaier {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  margin: 4rem auto;
  padding: 0 $commonLeftRightPadding;
  .innerSection {
    display: block;
    margin: 0;
    margin-bottom:2rem;
  }
  .heading {
    width: 100%;
    max-width: 600px;
    color: $yellow3;
    margin: 0 auto;
    display: inline-block;
    padding:0;
  }
  .designation {
    margin: 3px auto 1rem auto;
    padding:0;
    font-weight:400;
  }
  p{
   margin:1rem;
  }
}
</style>

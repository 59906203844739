<template>
  <div id="eventsMainContainer">
    <div class="item">
      <h3 class="heading">Ekam Events</h3>
      <p class="">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo id mollitia labore debitis
        obcaecati
        inventore fugiat soluta dolores? Porro ipsum blanditiis, accusamus nulla doloremque exercitationem
        mollitia placeat quia accusantium harum!
      </p>
    </div>

    <!----Package Card Section-->
    <router-link
      :to="'/events/MonsoonMagic'"
      class="routerLink more"
    >
      <div id="homePackagesDiv">
        <div
          id="cardPageDiv"
          class="cardWrapper"
        >
          <!--Card-->
          <a
            href="/event-gallery.html"
            class="card"
          >
            <!--Card Image-->
            <img
              class="cardImage"
              src="@/assets/image/events/1.jpg"
              alt=""
            >
            <!--Card Text-->
            <div class="cardTextDiv">
              <h5 class="cardTitle">Monsoon Magic -Reading Programmes for Children</h5>
              <p class="cardtext">08 Sep 2020</p>
            </div>
          </a>
        </div>
      </div>
    </router-link>
  </div>
</template>
<script>

export default {
  name: "events"
}
</script>
<style lang="scss" scoped>
//wall Section//
#eventsMainContainer {
  height: auto;
  min-height: 100vh;
  width: 100%;
  max-width: 1024px;
  padding: 0 $commonLeftRightPadding;
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
  .item {
    margin: 0;
    margin-bottom: 3rem;
  }
  .heading {
    width: 100%;
    max-width: 600px;
    color: $yellow3;
    margin: 0 0 1rem 0;
    display: inline-block;
  }
}

#cardPageDiv {
  flex-wrap: wrap;
}

.cardWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  overflow-x: auto;
}

.card {
  width: 250px;
  min-width: 250px;
  overflow: hidden;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 6px 19px -10px rgba(15, 42, 89, 0.2);
  cursor: pointer;

  &:hover {
    .cardImage {
      transform: scale(1.2);
    }
  }

  .cardImage {
    transition: all 0.7s;
    animation-timing-function: ease-in;
    width: 100%;
  }

  .cardTextDiv {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    background: rgb(1, 0, 13);
    color: $white;
    background: linear-gradient(
      0deg,
      rgba(1, 0, 13, 0.697917) 0%,
      rgba(0, 0, 0, 0.53) 20%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  .cardTitle {
    color: $white;
    text-align: left;
    font-weight: 300;
  }

  .cardText {
    color: $yellow1;
    font-size: 0.9rem;
    line-height: 100%;
    margin-top: 0.5rem;
  }

  .cardButton {
    background-image: linear-gradient(
      to left,
      #ff7b7b,
      #fb7373,
      #f86c6b,
      #f46464,
      #f05c5c
    );
    background-color: $yellow1;
    color: $white;
    text-shadow: 2px 2px 5px #ce5937;
    border-radius: 5rem;
    font-weight: 500;
    padding: 0.3rem 1rem;
    margin-top: 1rem;
    box-shadow: 0px 40px 24px -13px rgba(37, 62, 143, 0);
    animation-timing-function: cubic-bezier(0.06, 1.23, 0.83, 0.67);
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      transform: translateX(10px);
      box-shadow: 0px 44px 13px -15px rgba(37, 62, 143, 0.178);
    }
  }
}
</style>
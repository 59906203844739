<template>
  <div>
    <div class="container">
      <div>
        <div class="card">
          <h2>{{this.course.course.toUpperCase()}}</h2>
          <p class="age">{{this.course.age}}</p>
          <p>{{this.course.time}}</p>
          <p>{{this.course.text}}</p>
          <router-link
            :to="'/contact?course='+this.course.course"
            class="routerLink enquiryFormBt"
          >Enquire Admission</router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  data () {
    return {
      courses: [],
      course: []
    }
  },
  created () {
    this.courses = require("@/assets/data/course.json");
    this.course = this.courses;

  },
  mounted () {
    var param = this.$route.params.course;
    var courseCard = [];
    this.courses.forEach(function (item) {
      if (item.course == param) {
        courseCard = {
          course: item.course,
          age: item.age,
          time: item.time,
          text: item.text
        }
      }
    });
    this.course = courseCard;
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0b$commonLeftRightPadding;
  width: 100%;
  max-width: 1024px;
  margin: 4rem auto;
}
.card:hover {
  box-shadow: unset;
}
.card {
  background-color: $yellow3;
  text-align: left;
  color: $white;
  padding: 3rem $commonLeftRightPadding 2rem $commonLeftRightPadding;
  z-index: 1;
  margin: 1rem 0;
  box-shadow: 0 3px 6px 0 #b6b6b6;
  border-radius: 0.2rem;
  transition: box-shadow 0.5s;
  h2 {
    font-size: 1.7rem;
    font-weight: 500;
  }
  h2,
  p {
    margin: 2px 0;
  }
  .age {
    color: $black;
  }
  .enquiryFormBt {
    padding: $commonLeftRightPadding;
    display: inline-block;
    background-color: $yellow2;
    margin: 1rem 0;
  }
}
</style>


<template>
  <div class="Courses">
    <div id="coursesMainContainer">
      <div class="container">
        <div
          class="card"
          v-for="(course,index) in this.courses"
          :key="index"
        >
          <h2>{{course.course.toUpperCase()}}</h2>
          <p class="age">{{course.age}}</p>
          <p>{{course.time}}</p>
          <router-link
            :to="'/courseDetails/'+course.course"
            class="routerLink more"
          >more...</router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      courses: []
    }
  },
  created () {
    this.courses = require("@/assets/data/course.json");
  }
}
</script>
<style lang="scss" scoped>
#coursesMainContainer {
  width: 100%;
  height: auto;

  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 $commonLeftRightPadding;
    margin: 4rem auto;
    justify-content: center;
    @include media-md {
      width: 100%;
      max-width: 1200px;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .card:hover {
      box-shadow: unset;
    }
    .card {
      min-width: 100%;
      max-width: 100%;
      margin: 1rem 0;
      @include media-md {
        min-width: 330px;
        max-width: 330px;
        margin: 1rem;
      }
      background-color: $yellow3;
      text-align: left;
      color: $white;
      padding: 3rem $commonLeftRightPadding 1rem $commonLeftRightPadding;
      z-index: 1;
      box-shadow: 0 3px 6px 0 #b6b6b6;
      border-radius: 0.2rem;
      transition: box-shadow 0.5s;
      h2 {
        font-size: 1.7rem;
        font-weight: 500;
      }
      h2,
      p {
        margin: 2px 0;
      }
      .age {
        color: $black;
      }
      .more {
        font-size: 0.9rem;
        padding: 0.9rem 0;
        display: block;
        margin-top: 2rem;
      }
    }
  }
}
</style>


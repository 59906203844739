<template>
  <div class="testimonials">
    <div id="testimonialsMainContaier">
      <div class="innerSection">
        <div class="testimonialDiv testimonialRight">
          <img
            class="quote quoteStart"
            src="@/assets/icon/quote-start.png"
            alt=""
          >
          <p>
            Ekam is a wonderfully nurturing environment for any child. I am so thankful for the inclusivity of their classrooms, and the way their educators are uniquely equipped to provide individualized education to each child.
          </p>
          <p>My child is in the play group classroom at Ekam. She has always been warmly welcomed, and because of the love, kindness and excitement of everyone at Ekam, she has grown to love her teacher, her classmates, and her school!</p>
          <p>I have enrolled children in schools around Hyderabad, and I haven’t had such a positive experience as I have had in my child’s enrollment here. I strongly recommend it to any parent looking for an inclusive and nurturing environment.</p>
          <p>
            I am looking forward to seeing how my child continues to grow and learn here at Ekam!”</p>
          <img
            class="quote quoteEnd"
            src="@/assets/icon/quote-end.png"
            alt=""
          >
          <h4 class="personName">Lydia Pagan</h4>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
#testimonialsMainContaier {
  width: 100%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  text-align: center;
  margin: 4rem auto;
  padding: 0 $commonLeftRightPadding;
  .innerSection {
    display: block;
    margin: 0;
    margin-bottom: 2rem;
    .testimonialDiv {
      display: flex;
      flex-direction: column;
      border: 1px solid #ebebeb;
      padding: 2.5rem;
      margin: 2rem 0;
      -webkit-box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.027);
      box-shadow: 0px 0px 6px -2px rgba(0, 0, 0, 0.027);
      border-radius:2rem;

      .quote {
        width: 30px;
      }
      .quoteStart {
        align-self: flex-start;
      }
      .quoteEnd {
        align-self: flex-end;
      }
      .personName {
        color: #f89c1c;
        font-style: italic;
        align-self: flex-start;
      }
    }
  }
  .testimonialRight{
    border-top-right-radius: 0 !important;
  }
  .heading {
    width: 100%;
    max-width: 600px;
    color: $yellow3;
    margin: 0 auto;
    display: inline-block;
    padding: 0;
  }
  .designation {
    margin: 3px auto 1rem auto;
    padding: 0;
    font-weight: 400;
  }
  p {
    margin: 1rem;
  }
}
</style>

<template>
  <div class="Curriculum">
    <div id="curriculumMainContainer">

      <div id="curriculumContainerTextContainer">
        Finnish ECEC (Early childhood education care) is based on an integrated approach to
        care, education and teaching, the so called educare model. Critically teachers must
        support the development of the children’s play with a systematic and goal oriented
        approach by either guiding it from outside or participating in it.
      </div>
      <h1 class="hdContainer">
        <span class="hd hd1">“I DO, </span>
        <span class="hd hd2"> WE DO, </span>
        <span class="hd hd3"> YOU DO”</span>
      </h1>
      <div id="curriculumDetailesContainer">
        <div class="item">
          <h3 class="heading">Cognitive Development Through Exploration and Play</h3>
          <p>Play and exploration provide a platform for supporting the development of children’s literacy and
            numeracy skills as well as capitalizing on children’s natural curiosities and exuberance with the world
            around them. Early childhood is not only a period of amazing physical growth, it is also a time of
            remarkable mental development.
          </p>
        </div>
        <div class="item">
          <h3 class="heading">Relationships and Character Development</h3>
          <p>Ekam supports every child’s social and emotional development in ways that allow each child to form
            positive relationships with others. In providing a safe and caring learning environment where a child’s
            sense of self is nurtured, opportunities for the development of one’s character is a natural progression
            and intended outcome of the program.
          </p>
        </div>
        <div class="item">
          <h3 class="heading">Numeracy</h3>
          <p>Children need numeracy skills to solve problems, make sense of time, numbers, patterns and shapes.
            Ekam kids will develop confidence in making judgments or taking actions in all aspects of their daily lives
            through the ability to critically assess and reflect upon quantitative information. Providing our children with
            a strong numeracy foundation will allow for deeper explorations in mathematical thinking and concepts as
            the child enters into KG-12 school settings.
          </p>
        </div>
        <div class="item">
          <h3 class="heading">Physical Development and Wellness Awareness</h3>
          <p>Children are active learners in the process of making meaning of the world that surrounds them. How
            they choose to communicate affects their identities and is influenced by language. Ekam offers a socially
            and intellectually engaging environment where children’s communicative practices, languages, literacies
            and literate identities are valued and supported.
          </p>
        </div>
        <div class="item">
          <h3 class="heading">Literacy, Communication and Language
          </h3>
          <p>Children are active learners in the process of making meaning of the world that surrounds them. How
            they choose to communicate affects their identities and is influenced by language. Ekam offers a socially
            and intellectually engaging environment where children’s communicative practices, languages, literacies
            and literate identities are valued and supported.
          </p>
        </div>
        <div class="item">
          <h3 class="heading">Cultural Awareness and Diversity
          </h3>
          <p>Ekam seeks to provide an inclusive environment that celebrates the uniqueness of every child. In this
            caring environment, children will be nurtured to develop a deep understanding of the culture to which
            they belong, while instilling an appreciation and acceptance for the diversity of cultures that exist in our
            global society. Children will become socially responsible citizens sensitive to equitable and democratic
            practices that deepen their sense of cultural identity.
          </p>
        </div>

      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "curriculum"
}
</script>
<style lang="scss" scoped>
#curriculumMainContainer {
  height: auto;
  min-height: 100vh;
  width: 100%;
  max-width: 1024px;
  padding: 0 $commonLeftRightPadding;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin:4rem auto;
}
#curriculumContainerTextContainer {
  text-align: center;
  margin: 0;
}
.hdContainer {
  margin: 4rem auto;
  font-size: 1.6rem;
  @include media-sm {
    font-size: 2rem;
  }
  @include media-md {
    font-size: 2.4rem;
  }
  @include media-lg {
    font-size: 3.2rem;
  }
  .hd {
    margin-top: 0.6rem;
  }
  .hd1 {
    color: $yellow1;
  }
  .hd2 {
    color: $yellow2;
  }
  .hd3 {
    color: $yellow3;
  }
}
#curriculumDetailesContainer {
  text-align: center;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-items: center;
  .item {
    margin: 0;
    margin-bottom:3rem;
  }
  .heading {
    width: 100%;
    max-width: 600px;
    color: $yellow3;
    margin: 0 0 1rem 0;
    display: inline-block;
  }
}
</style>

